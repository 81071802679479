<template>
  <v-card>
    <v-card-title
      class="title primary white--text"
    >
      <v-icon
        dark
        left
      >
        {{ $vuetify.icons.values.presencia }}
      </v-icon>
      Presencia
    </v-card-title>
    <v-card-text
      class="pa-2"
      v-html="title"
    />
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="fentrada"
        color="primary"
        text
        small
        @click.stop="clickRegistrarSalida"
      >
        Registrar salida
      </v-btn>
      <v-btn
        v-else
        color="error"
        small
        @click.stop="clickRegistrarEntrada"
      >
        Registrar entrada
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { get } from 'vuex-pathify'
import { toDateTime } from '@/utils/date'

export default {
  data () {
    return {
      fentrada: null
    }
  },
  computed: {
    presencia: get('presencia'),
  },
  created () {
    this.fentrada = this.presencia.fentrada
    if (this.fentrada) {
      this.fentrada = toDateTime(this.fentrada)
      this.title = `
        Has registrado tu entrada el <strong>${this.$options.filters.shortDate(this.fentrada)}</strong>
        a las <strong>${this.$options.filters.shortTime(this.fentrada)}</strong>
      `
      if (this.presencia.descripcion) {
        this.title += ` en <strong>${this.presencia.descripcion}</strong>`
      }
    } else {
      this.title = 'Aún no has registrado tu entrada'
    }
  },
  methods: {
    clickRegistrarEntrada () {
      this.$appRouter.push({
        name: 'presencia__registrar-entrada-salida',
        params: { tipo: 'entrada' }
      })
    },
    clickRegistrarSalida () {
      this.$appRouter.push({
        name: 'presencia__registrar-entrada-salida',
        params: { tipo: 'salida' }
      })
    }
  }
}
</script>
