<template>
  <div>
    <v-list
      flat
      class="pt-0"
    >
      <v-list-group
        v-for="item in items"
        v-show="visibleHeader(item)"
        :key="item.id"
        no-action
      >
        <template #activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <div
          v-for="subItem in item.items"
          :key="subItem.id"
        >
          <v-list-item
            v-show="visibleItem(subItem)"
            class="subitem white"
            :to="subItem.to"
            @click.stop="clickItem(subItem)"
          >
            <v-list-item-avatar
              class="mx-0"
            >
              <v-icon
                small
              >
                {{ $vuetify.icons.values.caretRight }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-group>
    </v-list>
    <v-footer
      color="grey lighten-3"
      padless
    >
      <v-col
        class="text-center caption"
      >
        {{ version }} — {{ usuarioLogin }}
      </v-col>
    </v-footer>
    <v-dialog
      v-model="dialogLogout"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="title">
          ¿Quieres salir de Beta10?
        </v-card-title>
        <v-card-text>
          <v-alert
            type="warning"
            :value="syncPendientes > 0"
            dense
          >
            Tienes {{ syncPendientes }} {{ 'cambio'|pluralize(syncPendientes, 's') }} sin sincronizar
          </v-alert>
          <v-alert
            type="warning"
            :value="partesDescargados > 0"
            dense
          >
            Tienes {{ partesDescargados }} {{ 'parte'|pluralize(partesDescargados, 's') }} {{ 'descargado'|pluralize(partesDescargados, 's') }} sin subir
          </v-alert>
          Si cierras tu sesión deberás volver a autenticarte (es necesario tener conexión)
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click.stop="dialogLogout = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click.stop="logout"
          >
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { call, get } from 'vuex-pathify'
import mainMenuItems from './mainMenuItems'
import { permissions, hasViewPerm } from '@/utils/permissions'

export default {
  props: {
    syncPendientes: {
      type: Number,
      default: 0,
    },
    partesDescargados: {
      type: Number,
      default: 0,
    },
    usuarioPermisos: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      version: __BETA10_VERSION__,
      dialogLogout: false,
      items: mainMenuItems(),
    }
  },
  computed: {
    networkOnline: get('network/online'),
    usuarioAppsDisponibles: get('usuario/appsDisponibles'),
    usuarioLogin: get('usuario/login'),
    cronometroIdparteTrabajo: get('cronometro/idparte'),
  },
  methods: {
    ...call('usuario', {
      usuarioCerrarSesion: 'cerrarSesion',
    }),
    async logout () {
      this.dialogLogout = false
      await this.usuarioCerrarSesion()
      this.$appRouter.replace({ name: 'login' })
    },
    clickItem (item) {
      if (item.click) {
        if (item.click === 'logout') {
          if (this.cronometroIdparteTrabajo) {
            this.$alert.showDialogError('Antes de cerrar sesión debes detener el cronómetro')
          } else {
            this.dialogLogout = true
          }
        } else {
          this[item.click]()
        }
      }
    },
    visibleItem (item) {
      let visible = (
        // online / offline
        (this.networkOnline && item.showOnline) ||
        (!this.networkOnline && item.showOffline)
      ) && (
        // apps
        (!item.showApps) ||
        (item.showApps.some(app => this.usuarioAppsDisponibles.indexOf(app) >= 0))
      )
      if (item.id === 'partesAsignado' || item.id === 'partesAsignadoFechaFijada' || item.id === 'partesDescargados' || item.id === 'partesFinalizados') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.partes.verAsignadosAMi)
      } else if (item.id === 'parteNuevo') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.partes.nuevoParteTrabajo)
      } else if (item.id === 'ordenesPendientes') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.ordenes.verPendientes)
      } else if (item.id === 'ordenesFinalizadas') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.ordenes.verFinalizadas)
      } else if (item.id === 'ordenesFinalizadasTodos') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.ordenes.verFinalizadasOtros)
      } else if (item.id === 'agenda') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.planificacion.agenda)
      } else if (item.id === 'planificadorTecnicos') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.partes.verAsignadosOtros)
      } else if (item.id === 'materialEnVehiculo') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.vehiculos.verMaterial)
      } else if (item.id === 'stockEnVehiculo') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.vehiculos.verStock)
      } else if (item.id === 'puestosServicio') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.puestoServicio.id)
      } else if (item.id === 'cuadranteAgenda') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.cuadrante.id)
      } else if (item.id === 'rondasProgramadas' || item.id === 'rondasRealizadas') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.vigilancia.verRondas)
      } else if (item.id === 'partesNovedad') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.vigilancia.verPartesNovedad)
      } else if (item.id === 'llaveros') {
        // TODO: Agregar permiso
      } else if (item.id === 'pedidosCliente' || item.id === 'pedidosClientePendientes') {
        // TODO: Agregar permiso
      } else if (item.id === 'carteraClientes') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.cliente.id)
      } else if (item.id === 'clientesPotenciales') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.clientePotencial.id)
      } else if (item.id === 'presupuestosClientePendientes') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.presupuestocli.id)
      } else if (item.id === 'presupuestosClienteAceptados') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.presupuestocli.id)
      } else if (item.id === 'presupuestosClienteRechazados') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.presupuestocli.id)
      } else if (item.id === 'clientes') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.cliente.id)
      } else if (item.id === 'sistemas') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.sistema.id)
      } else if (item.id === 'entradasSalidas') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.presencia.verEntradasYSalidas)
      } else if (item.id === 'registrarEntrada') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.presencia.registrarEntrada)
      } else if (item.id === 'registrarSalida') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.presencia.registrarSalida)
      } else if (item.id === 'operacionesAbiertas') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.operaciones.id)
      } else if (item.id === 'operacionesCerradas') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.operaciones.id)
      } else if (item.id === 'operacionesInformadas') {
        visible = visible && hasViewPerm(this.usuarioPermisos, permissions.operaciones.id)
      } else if (item.id === 'configuracion') {
        // TODO: Agregar permiso
      } else if (item.id === 'sincronizacion') {
        // TODO: Agregar permiso
      }
      return visible
    },
    visibleHeader (item) {
      let visible = false
      if (item.items) {
        let i = 0
        while ((i < item.items.length) && (!visible)) {
          visible = this.visibleItem(item.items[i])
          i++
        }
      }
      return visible
    },
  },
}
</script>

<style scoped>
.subitem {
  height: 48px;
}
</style>
