import { APPS } from '@/utils/consts'

export default () => [
  {
    id: 'partesHeader',
    title: 'Partes de trabajo',
    items: [
      {
        id: 'partesAsignado',
        title: 'Asignados',
        to: { name: 'partes__parte-asignados-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
      {
        id: 'partesAsignadoFechaFijada',
        title: 'Fijados',
        to: { name: 'partes__parte-asignado-fecha-fijada' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
      {
        id: 'partesDescargados',
        title: 'Descargados',
        to: { name: 'offline__parte-descargados-list' },
        showOnline: true,
        showOffline: true,
        showApps: [APPS.tecnicos],
      },
      {
        id: 'partesFinalizados',
        title: 'Finalizados',
        to: { name: 'partes__finalizados-parte-asignados-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
      {
        id: 'parteNuevo',
        title: 'Nuevo',
        to: { name: 'partes__parte-trabajo-add' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
    ],
  },
  {
    id: 'ordenesHeader',
    title: 'Órdenes de trabajo',
    items: [
      {
        id: 'ordenesPendientes',
        title: 'Pendientes (sin asignar)',
        to: { name: 'ordenes__pendientes-orden-trabajo-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
      {
        id: 'ordenesPendientesResponsable',
        title: 'Pendientes (soy responsable)',
        to: {
          name: 'ordenes__pendientes-orden-trabajo-list',
          params: {
            _filter: {
              soyResponsable: {
                value: true,
                options: { fixed: true },
              },
            }
          },
        },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
      {
        id: 'ordenesFinalizadas',
        title: 'Finalizadas (he trabajado)',
        to: { name: 'ordenes__finalizadas-tecnico-orden-trabajo-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
      {
        id: 'ordenesFinalizadasTodos',
        title: 'Finalizadas',
        to: { name: 'ordenes__finalizadas-orden-trabajo-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
    ],
  },
  {
    id: 'planificacionHeader',
    title: 'Planificación',
    items: [
      {
        id: 'agenda',
        title: 'Agenda',
        to: { name: 'planificacion__agenda' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos, APPS.comerciales],
      },
      {
        id: 'planificadorTecnicos',
        title: 'Planificación de técnicos',
        to: { name: 'planificacion__planificador-tecnicos' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
    ],
  },
  {
    id: 'vehiculoHeader',
    title: 'Vehículo',
    items: [
      {
        id: 'materialEnVehiculo',
        title: 'Material retirado/a devolver',
        to: { name: 'vehiculos__material-vehiculo-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      },
      {
        id: 'stockEnVehiculo',
        title: 'Stock',
        to: { name: 'vehiculos__stock-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.tecnicos],
      }
    ],
  },
  {
    id: 'vigilanciaHeader',
    title: 'Vigilancia',
    items: [
      {
        id: 'puestosServicio',
        title: 'Puestos de servicio',
        to: { name: 'puestosservicio__puesto-servicio-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.vigilantes],
      },
      {
        id: 'cuadranteAgenda',
        title: 'Cuadrante',
        to: { name: 'cuadrantes__cuadrante-agenda' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.vigilantes],
      },
      {
        id: 'rondasProgramadas',
        title: 'Rondas programadas',
        to: { name: 'rondas__mronda-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.vigilantes],
      },
      {
        id: 'rondasRealizadas',
        title: 'Rondas realizadas',
        to: { name: 'rondas__ronda-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.vigilantes],
      },
      {
        id: 'partesNovedad',
        title: 'Partes de novedad',
        to: { name: 'partesnovedad__parte-novedad-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.vigilantes],
      },
      {
        id: 'llaveros',
        title: 'Llaveros',
        to: { name: 'custodiallaves__llavero-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.vigilantes],
      }
    ],
  },
  {
    id: 'almacenHeader',
    title: 'Almacén',
    items: [
      {
        id: 'pedidosCliente',
        title: 'Pedidos',
        to: { name: 'pedidos__pedido-cliente-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.almacen],
      },
      {
        id: 'pedidosClientePendientes',
        title: 'Pedidos pendientes',
        to: {
          name: 'pedidos__pedido-cliente-list',
          query: { 'solo_pendientes': true }
        },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.almacen],
      }
    ],
  },
  {
    id: 'presupuestosHeader',
    title: 'Presupuestos',
    items: [
      {
        id: 'presupuestosClientePendientes',
        title: 'Presupuestos pendientes',
        to: { name: 'presupuestos__pendientes-presupuestocli-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.comerciales],
      },
      {
        id: 'presupuestosClienteAceptados',
        title: 'Presupuestos aceptados',
        to: { name: 'presupuestos__aceptados-presupuestocli-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.comerciales],
      },
      {
        id: 'presupuestosClienteRechazados',
        title: 'Presupuestos rechazados',
        to: { name: 'presupuestos__rechazados-presupuestocli-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.comerciales],
      },
    ],
  },
  {
    id: 'clientesHeader',
    title: 'Clientes y sistemas',
    items: [
      {
        id: 'carteraClientes',
        title: 'Cartera de clientes',
        to: { name: 'clientes__cliente-con-potenciales-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.comerciales],
      },
      {
        id: 'clientesPotenciales',
        title: 'Clientes potenciales',
        to: { name: 'clientespotenciales__cliente-potencial-list' },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.comerciales],
      },
      {
        id: 'clientes',
        title: 'Clientes',
        to: { name: 'clientes__cliente-list' },
        showOnline: true,
        showOffline: false
      },
      {
        id: 'sistemas',
        title: 'Sistemas',
        to: {
          name: 'sistemas__sistema-list',
        },
        showOnline: true,
        showOffline: false
      }
    ],
  },
  {
    id: 'comercialHeader',
    title: 'Comercial',
    items: [
      {
        id: 'operacionesAbiertas',
        title: 'Operaciones abiertas',
        to: {
          name: 'operacionescrm__abiertas-operacioncrm-list',
          params: {
            _filter: {
              abierta: {
                value: true,
                options: { fixed: true },
              },
              asignada: {
                value: true,
                options: { fixed: true },
              },
            }
          },
        },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.comerciales],
      },
      {
        id: 'operacionesCerradas',
        title: 'Operaciones cerradas',
        to: {
          name: 'operacionescrm__cerradas-operacioncrm-list',
          params: {
            _filter: {
              abierta: {
                value: false,
                options: { fixed: true },
              },
              asignada: {
                value: true,
                options: { fixed: true },
              },
            }
          },
        },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.comerciales],
      },
      {
        id: 'operacionesInformadas',
        title: 'Operaciones informadas',
        to: {
          name: 'operacionescrm__operacioncrm-list',
          params: {
            _filter: {
              informada: {
                value: true,
                options: { fixed: true },
              },
            }
          },
        },
        showOnline: true,
        showOffline: false,
        showApps: [APPS.comerciales],
      },
    ]
  },
  {
    id: 'presenciaHeader',
    title: 'Presencia',
    items: [
      {
        id: 'entradasSalidas',
        title: 'Entradas y salidas',
        to: { name: 'presencia__presencia-list' },
        showOnline: true,
        showOffline: false
      },
      {
        id: 'registrarEntrada',
        title: 'Registrar entrada',
        to: {
          name: 'presencia__registrar-entrada-salida',
          params: { tipo: 'entrada' }
        },
        showOnline: true,
        showOffline: false
      },
      {
        id: 'registrarSalida',
        title: 'Registrar salida',
        to: {
          name: 'presencia__registrar-entrada-salida',
          params: { tipo: 'salida' }
        },
        showOnline: true,
        showOffline: false
      }
    ],
  },
  {
    id: 'tiemposHeader',
    title: 'Tiempos',
    items: [
      {
        id: 'tiempoTrabajado',
        title: 'Tiempo trabajado',
        to: { name: 'tiempos__tiempo-list' },
        showOnline: true,
        showOffline: false
      },
      {
        id: 'agendaTiempoTrabajado',
        title: 'Agenda de tiempos',
        to: { name: 'tiempos__tiempo-agenda'},
        showOnline: true,
        showOffline: false
      },
    ],
  },
  {
    id: 'sesionHeader',
    title: 'Sesión',
    items: [
      {
        id: 'configuracion',
        title: 'Configuración',
        to: { name: 'configuracion__configuracion' },
        showOnline: true,
        showOffline: true,
      },
      {
        id: 'usuarioedit',
        title: 'Cambiar contaseña',
        to: { name: 'configuracion__usuario-edit' },
        showOnline: true,
        showOffline: false,
      },
      {
        id: 'sincronizacion',
        title: 'Sincronización',
        to: { name: 'sincronizacion__sincronizacion' },
        showOnline: true,
        showOffline: true,
      },
      {
        id: 'salir',
        title: 'Salir',
        click: 'logout',
        showOnline: true,
        showOffline: true,
      },
    ],
  }
]
