<template>
  <b10-base>
    <v-card
      v-if="idronda"
    >
      <v-card-title
        class="title primary white--text"
      >
        <v-icon
          dark
          left
        >
          {{ $vuetify.icons.values.ronda }}
        </v-icon>Ronda en curso
      </v-card-title>
      <v-card-text
        class="pa-2"
      >
        <p>
          <strong>{{ ronda.mronda_descripcion }}</strong>
          comenzada el <strong>{{ ronda.fcomienzo|shortDate }}</strong>
          a las <strong>{{ ronda.fcomienzo|shortTime }}</strong>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          small
          @click.stop="clickContinuarRonda"
        >
          Continuar la ronda
        </v-btn>
      </v-card-actions>
    </v-card>
    <template
      v-else
    >
      <v-card
        v-if="idvigilante"
      >
        <v-card-title
          class="title primary white--text"
        >
          <v-icon
            dark
            left
          >
            {{ $vuetify.icons.values.ronda }}
          </v-icon>Rondas
        </v-card-title>
        <v-card-text
          class="pa-2"
        >
          No tienes una ronda en curso
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            small
            @click.stop="clickRealizarRonda"
          >
            Realizar ronda
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </b10-base>
</template>

<script>
import Data from './DashboardRondaData'

export default {
  props: {
    idronda: {
      type: Number,
      default: 0,
    },
    idvigilante: {
      type: Number,
      default: 0,
    }
  },
  data () {
    return {
      ronda: {},
    }
  },
  async created () {
    if (this.idronda) {
      this.ronda = await Data.selectRonda(this, this.idronda)
    }
  },
  methods: {
    clickContinuarRonda () {
      this.$emit('click-continuar-ronda')
    },
    clickRealizarRonda () {
      this.$emit('click-realizar-ronda')
    }
  }
}
</script>
