<template>
  <v-card>
    <v-card-title
      class="title primary white--text"
    >
      <v-icon
        dark
        left
      >
        {{ $vuetify.icons.values.cronometro }}
      </v-icon>Cronómetro parte Nº{{ idparte }}
    </v-card-title>
    <v-card-text
      class="pa-2"
    >
      <p>{{ para }} trabajado <strong>{{ cuenta }}</strong></p>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        text
        small
        @click.stop="clickCronometroDetener"
      >
        Detener el cronómetro
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { CRONOMETRO } from '@/utils/consts'
import { get } from 'vuex-pathify'
import { toDateTime, currentDateTime, diff as diffDate } from '@/utils/date'

export default {
  props: {
    inicio: {
      type: [Date, String],
      required: true
    },
    idparte: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      cuenta: ''
    }
  },
  computed: {
    cronometro: get('cronometro'),
    para () {
      if (this.cronometro.para === CRONOMETRO.para.paraTodos) {
        return 'Llevan'
      } else {
        return 'Llevas'
      }
    }
  },
  created () {
    this.updateCronometro()
    setInterval(this.updateCronometro, 5000)
  },
  beforeDestroy () {
    clearInterval(this.updateCronometro)
  },
  methods: {
    updateCronometro () {
      if (this.cronometro.idparte && this.cronometro.finicio) {
        this.cuenta = this.$options.filters.humanizeDuration(
          diffDate(currentDateTime(), toDateTime(this.cronometro.finicio))
        )
      }
    },
    clickCronometroDetener () {
      this.$emit('click-cronometro-detener')
    }
  }
}
</script>
