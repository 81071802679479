import { APIFilter } from '@/utils/api'

export default {
  async selectRonda (Vue, idronda) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idronda', idronda)
    const resp = await Vue.$api.call('ronda.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  }
}
