<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      persistent
      enable-resize-watcher
      fixed
      app
      color="grey lighten-3"
      width="270"
    >
      <main-menu
        :sync-pendientes="syncPendientes"
        :partes-descargados="partesDescargados.length"
        :usuario-permisos="usuario.permisos"
      />
    </v-navigation-drawer>
    <b10-toolbar
      :title="title"
      :back="false"
    >
      <v-app-bar-nav-icon
        slot="left"
        @click.stop="toggleDrawer"
      />
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-btn
        v-show="syncPendientes > 0 && network && network.online"
        large
        block
        color="red"
        :dark="!syncing"
        :disabled="syncing"
        @click.stop="sincronizar"
      >
        <v-icon
          left
        >
          {{ $vuetify.icons.values.sync }}
        </v-icon>
        Sincronizar {{ syncPendientes }} {{ 'cambio'|pluralize(syncPendientes, 's') }}
      </v-btn>
      <dashboard-presencia
        class="mt-2"
      />
      <dashboard-ronda
        v-if="presenciaIdpuestoServicio"
        class="mt-2"
        :idronda="vigilanciaIdronda"
        :idvigilante="usuario.idvigilante"
        @click-continuar-ronda="clickContinuarRonda"
        @click-realizar-ronda="clickRealizarRonda"
      />
      <dashboard-parte-novedad
        v-if="presenciaIdparteNovedad"
        class="mt-2"
      />
      <dashboard-cronometro
        v-if="cronometroIdparteTrabajo"
        class="mt-2"
        :inicio="cronometroFinicio"
        :idparte="cronometroIdparteTrabajo"
        @click-cronometro-detener="clickCronometroDetener"
      />
      <dashboard-parte-trabajo
        v-if="partesDescargados.length > 0"
        class="mt-2"
        :items="partesDescargados"
        :max-items="3"
        @click-ver-todos="clickVerTodosParte"
        @click-item-list="clickItemListParte"
      />
    </b10-page-content>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import { basePageMixin } from '@/mixins/basePageMixin'
import { PARTE_TRABAJO } from '@/utils/consts'
import Data from './IndexData'
import { doSync } from '@/sync/upload'
import MainMenu from './components/MainMenu'
import DashboardParteTrabajo from './components/DashboardParteTrabajo'
import DashboardCronometro from './components/DashboardCronometro'
import DashboardParteNovedad from './components/DashboardParteNovedad'
import DashboardPresencia from './components/DashboardPresencia'
import DashboardRonda from './components/DashboardRonda'
import { NextRoute } from '@/router'
import { fontColor } from '@/utils/ui'

export default {
  components: {
    MainMenu,
    DashboardParteTrabajo,
    DashboardCronometro,
    DashboardParteNovedad,
    DashboardPresencia,
    DashboardRonda,
  },
  mixins: [basePageMixin],
  data () {
    return {
      drawer: false,
      partesDescargados: [],
      operacionesPendientes: [],
      syncPendientes: 0,
      verDashboardOperaciones: false,
    }
  },
  computed: {
    cronometroFinicio: get('cronometro/finicio'),
    cronometroIdparteTrabajo: get('cronometro/idparte'),
    presenciaIdparteNovedad: get('presencia/idparteNovedad'),
    presenciaIdpuestoServicio: get('presencia/idpuestoServicio'),
    syncing: get('sync/syncing'),
    usuario: get('usuario'),
    network: get('network'),
    vigilanciaIdronda: get('vigilancia/idronda'),
  },
  watch: {
    syncing (newSyncing) {
      // deja de sincronizar (autoSync) -> refrescar cambios pendientes
      if (!newSyncing) {
        this.updateSyncPendientes()
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Beta10'
      await this.cargarPartesDescargados()
      await this.updateSyncPendientes()
    },
    toggleDrawer () {
      this.drawer = !this.drawer
    },
    async sincronizar () {
      if (!this.syncing) {
        try {
          await doSync(this.$offline, this.$api, this.$loading, this.$alert)
        } finally {
          await this.updateSyncPendientes()
        }
      } else {
        this.$alert.showSnackbarInfo('Ya está sincronizando los cambios')
      }
    },
    async cargarPartesDescargados () {
      const items = await Data.selectPartes(this, this.usuario.idtecnico)
      this.partesDescargados = this.formatPartesDescargados(items)
    },
    formatPartesDescargados (items) {
      for (let item of items) {
        item.title = this.$offline.parteTrabajo.title(item)
        item.subtitle = this.$offline.parteTrabajo.subtitle(item)
        if (item.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente) {
          item.avatarText = 'P'
          item.avatarColorBackground = PARTE_TRABAJO.colores.pendiente
          item.avatarColorText = fontColor(PARTE_TRABAJO.colores.pendiente)
        } else {
          item.avatarText = 'F'
          item.avatarColorBackground = PARTE_TRABAJO.colores.finalizado
          item.avatarColorText = fontColor(PARTE_TRABAJO.colores.finalizado)
        }
      }
      return items
    },
    clickItemListParte (item) {
      this.$appRouter.push({
        name: 'offline__parte-trabajo-view',
        params: {
          idparte_trabajo: item.parte_trabajo.idparte_trabajo
        }
      })
    },
    clickVerTodosParte () {
      this.$appRouter.push({
        name: 'offline__parte-descargados-list'
      })
    },
    clickCronometroDetener () {
      this.$appRouter.push({
        name: 'offline__cronometro-detener'
      })
    },
    async updateSyncPendientes () {
      this.syncPendientes = await this.$offline.sync.syncCountPendiente()
    },
    clickContinuarRonda () {
      this.$appRouter.push({
        name: 'rondas__ronda-realizar',
        params: {
          idronda: this.vigilanciaIdronda,
        },
      })
    },
    clickRealizarRonda () {
      this.$appRouter.push({
        name: 'rondas__mronda-list',
        params: {
          nextRoute: NextRoute('rondas__ronda-realizar', { idronda: null }),
        },
      })
    },
  }
}
</script>
