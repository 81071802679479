export default {
  async _addSubqueriesPartes (Vue, rows) {
    for (let row of rows) {
      row.direccion = await Vue.$offline.clienteDireccion.formatearDireccion(row.cliente_direccion)
    }
    return rows
  },
  async selectPartes (Vue, idtecnico) {
    const tables = Vue.$offline.db.tables
    const inPartesTecnicoActual = await Vue.$offline.parteTrabajoTecnico.inPartesDescargados(idtecnico)
    const query = Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .leftOuterJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .leftOuterJoin(
        tables.cliente_direccion,
        tables.sistema.idcliente_direccion_sist.eq(tables.cliente_direccion.idcliente_direccion)
      )
      .innerJoin(
        tables.tsistema,
        tables.sistema.idtsistema.eq(tables.tsistema.idtsistema)
      )
      .innerJoin(
        tables.tactuacion_tsistema,
        tables.orden_trabajo.idtactuacion_tsistema.eq(tables.tactuacion_tsistema.idtactuacion_tsistema)
      )
      .innerJoin(
        tables.tactuacion,
        tables.tactuacion_tsistema.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .where(
        tables.parte_trabajo.idparte_trabajo.in(inPartesTecnicoActual)
      )
      .orderBy(tables.parte_trabajo.idparte_trabajo, Vue.$offline.db.order.ASC)
    const rows = await query.exec()
    return this._addSubqueriesPartes(Vue, rows)
  },
}
